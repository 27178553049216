import React from "react"
import { Row, Col } from "react-bootstrap"
import Img from "gatsby-image"
import { Link } from "gatsby"

class PostsList extends React.Component {
  render() {
    const data = this.props
    const posts = data.posts.map((x) => x.node)
    return (
      <div>
        {posts &&
          posts.map((post) => (
            <div class="post-tile">
              <Row>
                <Col md="8" className={"mx-auto"}>
                  <div className="blog-container">
                    <Img
                      fluid={post.frontmatter.coverPhoto.childImageSharp.fluid}
                    />
                    <div className={"blog-card"}>
                      <h4>{post.frontmatter.title}</h4>
                      <Link to={post.fields.slug} className={"btn btn-primary"}>
                        Read More
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ))}
      </div>
    )
  }
}

export default PostsList
