import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PostsList from "../components/posts-list"

const BlogPageTemplate = ({ posts }) => (
  <Layout>
    <Seo title="Cake Shop In Mumbai| Send/Order Cakes Online - Deliciae Cakes" />
    <div className={"light-bg py-5"}>
      <div className={"container"}>
        <h1 className={"text-center mb-5 blog-title"}>Deliciae Blog</h1>
        <p>A blog where we delve into stories behind everything at Deliciae & give you an inside look on the brand, from Bunty Mahajan's eye. Sharing our experiences, learnings & challenges with the Deliciae family.</p>
        <PostsList posts={posts} />
      </div>
    </div>
  </Layout>
)

const BlogPage = ({ data }) => {
  return <BlogPageTemplate posts={data.allMarkdownRemark.edges} />
}

export default BlogPage
export const query = graphql`
  query BlogPageTemplate {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "post-page" } } }
      sort: { order: DESC, fields: frontmatter___publishedAt }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            content
            gist
            coverPhoto {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
                publicURL
            }
            metaDescription
            publishedAt
            title
            seoTitle
          }
        }
      }
    }
  }
`
